@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('/fonts/Montserrat-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('/fonts/Montserrat-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('/fonts/Montserrat-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('/fonts/Montserrat-Bold.ttf');
  font-weight: 700;
}

@layer base {
  html {
    /* scroll-behavior: smooth; */
    scroll-padding-top: 175px;
  }

  main {
    @apply bg-white;
  }

  html,
  body {
    font-family: 'museo-sans', sans-serif;
    height: 100%;

    @apply relative;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button {
    @apply border-none bg-transparent;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .button-blue,
  .blueButton {
    @apply button-base bg-hh-blue border-hh-blue text-white hover:text-white hover:bg-hh-denim focus:ring-offset-white focus:ring-hh-blue;
  }

  .button-green {
    @apply button-base bg-hh-green border-hh-green text-white hover:bg-hh-salem focus:ring-offset-white focus:ring-hh-green;
  }

  .button-mine-shaft-opaque {
    @apply button-base bg-transparent border-hh-mine-shaft text-hh-mine-shaft hover:bg-hh-mine-shaft hover:text-white focus:ring-offset-white focus:ring-hh-mine-shaft;
  }

  .button-white-opaque {
    @apply button-base bg-transparent border-white text-white hover:bg-white hover:text-hh-mine-shaft focus:ring-offset-hh-mine-shaft focus:ring-white;
  }

  .button-transparent {
    @apply button-base bg-transparent border-transparent text-white hover:bg-hh-blue focus:ring-offset-black focus:ring-hh-denim;
  }

  .button-link {
    @apply font-hh-form text-sm hover:underline focus:opacity-75;
  }

  .content {
    @apply content-base content-base-headings children-h2:uppercase;
  }

  .content a {
    @apply underline transition-all hover:scale-105 focus:scale-95;
  }

  .content :where(h2 + *, h3 + *, h4 + *, h5 + *, h6 + *) {
    margin-top: 0.25rem !important;
  }

  .content *:first-child {
    margin-top: 0.25rem !important;
  }

  .content-lg {
    @apply content-base content-base-lg-headings children-headings:uppercase;
  }

  .content li,
  .content-lg li {
    @apply relative py-3 pl-9;
  }

  .content li:after,
  .content-lg li:after {
    background-image: url('/icons/usp-check.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @apply content-[''] h-5 w-5 absolute top-3 left-1;
  }

  .title {
    @apply font-hh-heading font-bold;
  }

  .paragraph {
    @apply font-hh-paragraph font-light;
  }

  .label {
    @apply rounded-3xl bg-hh-blue text-white lg:px-6 px-3 sm:px-4 py-[6px] lg:text-s text-xs font-hh-form;
  }

  .tag {
    @apply rounded-3xl bg-hh-concrete flex-none text-hh-mine-shaft px-4 py-1 text-s font-hh-form;
  }

  .input {
    @apply button-base-ring w-full rounded-3xl bg-transparent text-hh-mine-shaft !shadow-none !ring-0 placeholder:text-slate-500 border-2 border-solid border-hh-mine-shaft font-hh-form;
  }

  .select {
    @apply button-base-ring font-light text-sm lg:text-m bg-hh-mine-shaft/10 text-hh-mine-shaft bg-clip-padding border border-solid border-hh-mine-shaft/10 bg-no-repeat rounded-3xl py-[5px] pl-5 pr-10 appearance-none focus:ring-offset-white focus:ring-hh-mine-shaft;

    background-image: url('/icons/chevron-down.svg');
    background-position: right 1rem center;
    background-size: 12px;
    background-rotate: 90deg;
  }
}

@layer utilities {
  .containerize {
    @apply max-w-screen-3xl mx-auto;
  }

  .content-base-lg-headings {
    @apply children-h1:text-4xl children-h1:leading-[35px] md:children-h1:text-5xl md:children-h1:leading-[50px] xl:children-h1:text-6xl xl:children-h1:leading-[70px];
    @apply children-h2:text-4xl md:children-h2:text-5xl xl:children-h2:text-6xl;
    @apply children-h3:text-3xl;
  }

  .content-base-headings {
    @apply children-headings:leading-[50px];

    @apply children-h1:text-2xl md:children-h1:text-3xl xl:children-h1:text-4xl;
    @apply children-h2:text-2xl lg:children-h2:text-3xl xl:children-h2:text-4xl;
    @apply children-h3:text-2xl;
  }

  /*
   we use mt-4 here for headings because the font has a very weird line-height
  */
  .content-base {
    @apply font-hh-paragraph font-light text-sm md:text-m;
    @apply children-p:font-hh-form;
    @apply children-p:leading-6 lg:children-p:leading-7 children-p:text-sm lg:children-p:text-m;
    @apply children-headings:mt-4 children-h1:mt-0 children-headings:mb-2 children-p:mb-5 children-p:mt-5;
    @apply children-headings:font-hh-heading children-headings:font-bold children-h1:uppercase children-h1:font-bold;
  }

  .content-base .table-hack-wrapper {
    @apply table table-fixed w-full;
  }

  .content-base .table-hack-cell {
    @apply table-cell overflow-x-auto w-full;
  }

  .content-base table {
    @apply bg-hh-concrete font-hh-form text-sm font-medium;
  }

  .content-base table td {
    @apply table-cell text-hh-mine-shaft;
  }

  .content-base table tr td {
    @apply py-2 border-[1px] border-hh-silver px-4;
  }

  .content-base img {
    @apply max-w-full;
  }

  .section-none {
    @apply py-0;
  }

  .section-text-top {
    @apply pt-10 lg:pt-16;
  }

  .section-text-bottom {
    @apply pb-10 lg:pb-16;
  }

  .section-text {
    @apply py-10 lg:py-16;
  }

  .section {
    @apply py-10 sm:py-12 lg:py-16 xl:py-20;
  }

  .spacing-top {
    @apply pt-6 xl:pt-12;
  }

  .spacing-bottom {
    @apply pb-6 xl:pb-12;
  }

  .button-base-ring {
    @apply focus:ring-2 focus:ring-offset-2 transition-all;
  }

  .button-base {
    @apply button-base-ring border-[1px] border-solid font-hh-heading font-normal rounded-3xl py-2 md:py-3 sm:min-w-[175px] text-sm md:text-m !no-underline text-center px-5 md:px-7 inline-flex items-center justify-center uppercase transition-colors;
  }

  .ellipsis {
    @apply overflow-hidden text-ellipsis whitespace-nowrap;
  }

  .knowledge-image {
    box-shadow: 1px 1px 7px 0 rgb(41 41 41 / 32%);
    @apply rotate-12;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .text-align-inherit {
    text-align: inherit;
  }

  .filter-white {
    filter: invert(93%) sepia(100%) saturate(0%) hue-rotate(97deg) brightness(104%) contrast(102%);
  }

  .filter-blue {
    filter: invert(47%) sepia(95%) saturate(2829%) hue-rotate(178deg) brightness(103%) contrast(105%);
  }

  .filter-gray {
    filter: invert(47%) sepia(5%) saturate(7%) hue-rotate(26deg) brightness(90%) contrast(89%);
  }

  .box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }

  .radar {
    animation: 3s linear 0s infinite normal none running rotation;
  }

  @media (min-width: 992px) {
    .radar {
      background-image: radial-gradient(150px 90px, #00b7e5 100px, rgba(0, 183, 229, 0) 160px);
      clip: rect(100px, 100px, 200px, 0);
      border-radius: 100px;
    }
  }

  @media (max-width: 991px) {
    .radar {
      background-image: radial-gradient(120px 70px, #00b7e5 90px, rgba(0, 183, 229, 0) 120px);
      clip: rect(75px, 75px, 150px, 0);
      border-radius: 75px;
    }
  }
}

#CybotCookiebotDialogPoweredbyCybot {
  display: none !important;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.react-pdf__Page__canvas {
  @apply box-shadow;
}

/* ----------START PARSECONTENT---------- */
.parsecontent h1 {
  @apply mb-5;
}

.parsecontent strong,
.parsecontent b,
.parsecontent h1 strong,
.parsecontent h1 b,
.parsecontent h2 strong,
.parsecontent h2 b,
.parsecontent h3 strong,
.parsecontent h3 b,
.parsecontent h4 strong,
.parsecontent h4 b,
.parsecontent h5 strong,
.parsecontent h5 b {
  @apply font-bold;
}

.parsecontent h1:last-child,
.parsecontent h2:last-child,
.parsecontent h3:last-child,
.parsecontent h4:last-child,
.parsecontent h5:last-child,
.parsecontent p:last-child {
  @apply mb-0;
}

.parsecontent a {
  @apply font-bold;
}

.parsecontent a:hover {
  @apply underline;
}

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption img {
  @apply w-full;
}

.wp-caption-text {
  @apply paragraph !text-sm text-black/50 !mt-2 text-center;
}
/* ----------END PARSECONTENT---------- */

/* ----------START FORM---------- */
.form-duuf-group {
  @apply mb-5;
}

.form-duuf-group .form-duuf-select__control,
.form-duuf-input,
.form-duuf-textarea {
  @apply input h-12;
}

.form-duuf-checkbox-label {
}

.form-duuf-group .form-duuf-select__single-value {
  @apply px-3;
}

.form-duuf-input,
.form-duuf-textarea {
  @apply px-5;
}

.form-duuf-textarea {
  @apply pt-3 h-40;
}

.form-duuf-file-input {
  @apply text-slate-500 file:mr-4 file:rounded-full file:border-none file:bg-slate-800 file:py-2 file:px-4 file:text-white file:hover:bg-blue-500 file:hover:transition-all;
}

.form-duuf-checkbox {
  @apply mr-4 min-h-[16px] min-w-[16px] rounded-none border-none bg-slate-100 text-slate-500 focus:border-none focus:outline-none active:border-none active:outline-none;
}

.form-duuf-group-consent a {
  @apply underline hover:opacity-75;
}

.form-duuf-select__menu {
  @apply !rounded-none;
}

.form-duuf-select__menu-list {
  @apply px-3 py-0 rounded-none;
}

.form-duuf-select__option {
  @apply my-1 cursor-pointer rounded-none transition-all !font-hh-form !text-sm;
}

.form-duuf-select__option:first-of-type {
  @apply mt-3;
}

.form-duuf-select__option:last-of-type {
  @apply mb-3;
}

.form-duuf-select__option:active {
  @apply !bg-hh-blue/25 !text-white;
}

.form-duuf-select__option--is-focused {
  @apply !bg-hh-blue/25;
}

.form-duuf-select__option--is-selected {
  @apply !bg-hh-blue;
}

.form-duuf-select__input {
  @apply !border-none !shadow-none !outline-none;
}

.form-duuf-select__single-value {
  @apply !top-[30px] !left-[12px] !m-0 !text-slate-800;
}

.form-duuf-select__placeholder {
  @apply !top-[24px] !left-[12px] !m-0 !text-slate-500;
}

.form-duuf-group {
  @apply relative;
}

.form-duuf-error {
  @apply text-s text-red-500 font-hh-form;
}

.form-duuf-label {
  @apply text-sm paragraph font-bold mb-1 block;
}

.form-duuf-submit-disabled {
  @apply opacity-50 hover:bg-blue-500;
}

.form-duuf-submit > button {
  @apply button-blue;
}

.form-duuf-loading {
  @apply absolute left-0 top-0 z-10 h-full w-full bg-white bg-opacity-50;
}
/* ----------START FORM---------- */

#nprogress .bar {
  background: #00a1ff !important;
}

#nprogress .spinner-icon {
  border-top-color: #00a1ff !important;
  border-left-color: #00a1ff !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #00a1ff, 0 0 5px #00a1ff !important;
}
